<template>
  <div class="DeveloperUserProjects">
    <div class="DeveloperUserItem" @click.prevent="loadDeveloperUsers()">
      <div class="DeveloperUserItem__username">
        {{ currentDeveloperUser.username }}
      </div>
      <div class="DeveloperUserItem__email">
        {{ currentDeveloperUser.email }}
      </div>
    </div>
    <ul>
      <li v-for="(project, index) in developerUserProjects" :key="index">
        <project-item :project="project" :projectindex="index" />
      </li>
    </ul>
    <Loader :show="busy" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectItem from './DeveloperUserProjectItem';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'DeveloperUserProjects',
  components: {
    ProjectItem,
    Loader,
  },
  data() {
    return {
      busy: false,
    };
  },
  computed: {
    ...mapGetters(['developerUserProjects', 'currentDeveloperUser']),
  },
  mounted() {
    this.busy = true;
    this.$store
      .dispatch('getDeveloperUserProjects')
      .then(() => {
        this.busy = false;
      })
      .catch(() => {
        this.busy = false;
        this.loadDeveloperUsers();
      });
  },
  methods: {
    loadDeveloperUsers() {
      this.$router.push({ name: 'developerUsersList' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/developer/developer-user-projects/DeveloperUserProjects.scss';
</style>
