<template>
  <div
    class="DeveloperUserProjectItem"
    @click.prevent="loadDeveloperUserProject()"
  >
    <div class="DeveloperUserProjectItem__card">
      <div class="DeveloperUserProjectItem__title">{{ project.name }}</div>
      <div class="DeveloperUserProjectItem__application-types">
        <img
          v-if="hasAndroidApplication"
          class="DeveloperUserProjectItem__application-icon"
          src="@/assets/img/android-icon.svg"
        />
        <img
          v-if="hasIosApplication"
          class="DeveloperUserProjectItem__application-icon"
          src="@/assets/img/ios-icon.svg"
        />
        <img
          v-if="hasWebApplication"
          class="DeveloperUserProjectItem__application-icon"
          src="@/assets/img/web-icon.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeveloperUserProjectItem',
  props: {
    project: {
      type: Object,
      required: true,
    },
    projectindex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    applicationTypes() {
      if (this.project.applicationTypes) {
        return this.project.applicationTypes;
      }
      return [];
    },
    hasAndroidApplication() {
      return this.applicationTypes.indexOf(0) > -1;
    },
    hasIosApplication() {
      return this.applicationTypes.indexOf(1) > -1;
    },
    hasWebApplication() {
      return this.applicationTypes.indexOf(2) > -1;
    },
  },
  methods: {
    loadDeveloperUserProject() {
      this.$store.dispatch('getDeveloperUserProject', this.project);
      this.$router.push({
        name: 'developerUserProject',
        params: {
          userId: this.$route.params.userId,
          projectId: this.project.projectId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/developer/developer-user-projects/DeveloperUserProjectItem.scss';
</style>
